export const CONSTANT = {
	REQUIRED: 'Campo obbligatorio',
	NULLABLE: 'Campo obbligatorio',
	ACTION_ADD: 'Aggiungi',
	ACTION_CREATE: 'Crea',
	ACTION_SAVE: 'Salva',
	ACTION_DELETE: 'Cancella',
	REC_NEW: 'Nuovo',
	REC_UPDATE: 'Modifica',
	YEAR: 'Inserire anno corretto',
	EMAIL: 'Formato Email non corretto',
	NUMBER_POSITIVE: 'Inserire un valore positivo',
	NUMBER: 'Inserire un valore numerico',
	INTEGER: 'Inserire un valore intero',
	CURRENT_YEAR: new Date().getFullYear(),
	MOCK_DELAY: 1000,
	MOCK_ENABLED: false,
	STEP: 'STEP',
	INIT: 'INIT',
	DOCUMENT_REQUIRED: 'Inserisci il documento',
	PIC_REQUIRED: 'Inserisci la foto',
	FISCAL_CODE: 'Codice fiscale non corretto',
	VAT_CODE: 'Partita iva non corretta'
};

export const CONSTANT_SERVICE = {
	OFFERS: 'offers',
	TAGS: 'tags',
	WORKFLOW: 'workflow'
};

export const STATUS = {
	OK: 200,
	ERROR: 500
};

export const MESSAGE = {
	SUCCESS_DOC_SAVE: 'Documenti correttamente salvati',
	SUCCESS_SAVE: 'Dati correttamente salvati',
	SUCCESS_EDIT: 'Dati correttamente aggiornati',
	ERROR: 'Ops! Qualcosa è andato storto'
};

export const FILES = {
	APE: 'ape-pre-intervento',
	CI: 'documento-identita',
	CF: 'codice-fiscale',
	EDIFICIO_EXT: 'edificio-esterno',
	PORTE: 'porte-interne',
	FINESTRE: 'finestre',
	SCHEDA_CALDAIA: 'scheda-tecnica-caldaia',
	CALDAIA: 'caldaia',
	MURI: 'muri',
	ELEMENTI_RADIANTI: 'elementi-radianti',
	ELEMENTO_AUTORISCALDANTE: 'elemento-autoriscaldante',
	BOLLETTA_E: 'bolletta-energia-elettrica',
	BOLLETTA_G: 'bolletta-combustibile',
	VISURA: 'visura-catastale',
	PLANIMETRIA: 'planimetria',
	CONSENSO: 'consenso-gdpr',
	AGIBILITA: 'agibilita',
	ABUSI: 'abusi',
	VINCOLI: 'vincoli',
	LAVORI_EXTRA: 'lavori-extra',
	DISEGNI_PROGETTO: 'disegni-progetto',
	PROGETTAZIONE: 'progettazione',
	VARIE: 'varie',
	PROGETTO_PRELIMINARE: 'progetto-preliminare',
	PROGETTO_ESECUTIVO: 'progetto-esecutivo',
	ALTRI_DOCUMENTI: 'altri-documenti',
	TAVOLE: 'tavole',
	RELAZIONE: 'relazione',
	COMPUTO_METRICO: 'computo-metrico',
	STRATIGAFIA_CALCOLI: 'stratigrafia-e-calcoli',
	ANTI_MAFIA: 'attestato-anti-mafia',
	ATTESTAZIONE_SOA: 'attestazione-soa',
	ASSENZA_CARICHI_PENALI: 'dichiarazione-assenza-carichi-penali',
	ASSENZA_CARICHI_TRIBUTARI: 'dichiarazione-assenza-carichi-tributari',
	DURC: 'durc',
	GDPR: 'gdpr',
	VISURA: 'visura-catastale',
	ATTO_PROPRIETA: 'atto-propr',
	LAVORI_RISTRUTTURAZIONE: 'lavori-ristrutturazione',
	DOCUMENTO_FIRMATO_CONTRATTO: 'doc-contr-sign',
	DOCUMENTO_FIRMATO_PRE_CONTRATTO: 'doc-pre-contr-sign',
	ASSEVERAZIONI: 'asseverazioni',
	TABELLE_MILLESIMALE: 'tab-millesimale-prop',
	REGOLAMENTO_CONDOMINIO: 'regolamento-condominio',
	ATTO_COSTITUZIONE_CONDOMINIO: 'atto-costituzione-condominio',
	REGISTRO_ANAGRAFICA: 'registro-anagrafica',
	PRATICHE_EDILIZIE: 'pratiche',
	CI_COMM: 'documento-identita-committente',
	CF_COMM: 'codice-fiscale-committente',
	CONSENSO_PROPRIETARIO: 'consenso-propr',
};

export const TABS = {
	WORKFLOW: 'workflow',
	INDEX: 'index',
	OWNER: 'owner',
	CONDOMINIUM: 'condominium',
	HOUSING: 'housing',
	GENERAL: 'general',
	SPECIFIC: 'specific',
	FIXTURES: 'fixtures',
	INSTALLATIONS: 'installations',
	CONTRACTS: 'contracts',
	OTHER: 'other',
	ATTACHMENTS: 'attachments',
	BACKOFFICE: 'backoffice',
	DETAILS:'details'
};

export const CONSTANT_KEYS = {
	BE_APP_NAME: 'appName',
	BE_APP_ICON: 'appIcon',
	BE_GMAPS_KEY: 'appGoogleMapsApiKey',
	BE_AGENCY_STYLE: 'clientAgencyStyle',
	BE_CONTRACT_MULTILINE: 'appContractMultiline',
	BE_CONTRACT_SUPPLY_INVOICE: 'appContractSupplyInvoice',
	BE_CONTRACT_AGENCY_MODE: 'appContractAgencyMode',
	BE_USERNAME_GENERATION: 'usernameGeneration',
	BE_RELATIONS_CUSTOMER_TYPE_TYPOLOGY_USE:
		'appRelationsCustomerTypeTypologyUse',
	BE_ECOBONUS_GDPR: 'appEcobonusGdpr',
	CONTRACT: 'contract',
	PRECONTRACT: 'precontract',
	BPARTNER: 'bpartner',
	APP_CODE: 'appCode',
	PRIVACY_URL: 'appPrivacyUrl',
	CUSTOMER_TRANSLATION:'customerTranslation',
	URL_GOOGLE_PLAY_APP:'urlGoogleApp',
	URL_APPLE_APP:'urlAppleApp',
	TOP_BAR_COLOR:'topBarColorStyle',
};

export const CONSTANT_CALENDAR = {
	typeList: [
		{
			id: 'apt',
			value: 'APPUNTAMENTO',
			default: true
		},
		{
			id: 'tkt',
			value: 'SUPPORTO',
			default: false
		},
		{
			id: 'chat',
			value: 'GENERALE',
			default: false
		}
	],
	statusList: [
		{
			id: 'draft',
			value: 'BOZZA',
			color: 'primary'
		},
		{
			id: 'work',
			value: 'IN LAVORAZIONE',
			color: 'success'
		}
	]
};

export const TYPES = [
	{
		code: 'text'
	},
	{
		code: 'data'
	},
	{
		code: 'rest'
	},
	{
		code: 'enum'
	}
];

export const ENUM_STATUS = [
	{
		label: 'APERTO',
		code: 'open'
	},
	{
		label: 'COMPLETATO',
		code: 'close'
	}
];

export const ENUM_STATUS_WORKFLOW = [
	{
		label: 'IN ESECUZIONE',
		code: 'OPEN'
	},
	{
		label: 'TERMINATO',
		code: 'CLOSE'
	},
	{
		label: 'BOZZA',
		code: 'DRAFT'
	}
];

export const ENUM_TYPE_IMMO = [
	{
		label: 'CONDOMINI',
		code: 'C'
	},
	{
		label: 'VILLE A SCHIERA',
		code: 'V'
	},
	{
		label: 'CASE',
		code: 'U'
	}
];

export const ENUM_STATUS_OPPORTUNITY = [
	{
		label: 'APERTO',
		code: 'OPEN'
	},
	{
		label: 'CHIUSO',
		code: 'CLOSE'
	}
];

export const ENUM_TOBECHECK = [
	{
		label: 'SI',
		code: true
	},
	{
		label: 'NO',
		code: false
	}
];

export const ENUM_STATUS_LEADS = [
	{
		label: 'APERTO',
		code: 'OPEN'
	},
	{
		label: 'CHIUSO',
		code: 'CLOSE'
	}
];

export const ENUM_STATUS_CAMPAIGNS = [
	{
		label: 'APERTA',
		code: 'OPEN'
	},
	{
		label: 'CHIUSA',
		code: 'CLOSE'
	}
];

export const ENUM_STATUS_CAMPAIGN_ELEMS = [
	{
		label: 'KO',
		code: 'FAILED'
	},
	{
		label: 'Fissato appuntamento',
		code: 'APPOINTMENT'
	},
	{
		label: 'Bozza',
		code: 'DRAFT'
	},
	{
		label: 'Da richiamare',
		code: 'RECALL'
	}
];

export const ENUM_TYPE_LEADS = [
	{
		label: 'PRIVATO',
		code: 'P'
	},
	{
		label: 'AZIENDA',
		code: 'C'
	}
];

export const ENUM_PAYMENT = [
	{
		label: 'DA PAGARE',
		code: 'KO'
	},
	{
		label: 'DA STORNARE',
		code: 'DS'
	},
	{
		label: 'PAGATO',
		code: 'OK'
	},
	{
		label: 'STORNATO',
		code: 'ST'
	}
];

export const ENUM_INVOICE_STATUS = [
	{
		label: 'PAGATA',
		code: 'payed'
	},
	{
		label: 'PAGATA PARZ.',
		code: 'partialPayed'
	},
	{
		label: 'DA PAGARE',
		code: 'notPayed'
	},
];

export const ENUM_TYPOLOGY_BPARTNER = [
	{
		label: 'PERSONA FISICA',
		code: 'PF'
	},
	{
		label: 'PERSONA GIURIDICA',
		code: 'PG'
	},
	{
		label: 'AMM. DI CONDOMINIO',
		code: 'AC'
	},
	{
		label: 'CONDOMINIO',
		code: 'CC'
	},
	{
		label: 'STUDIO AMM. DI CONDOMINIO',
		code: 'SA'
	}
]

export const ENUM_STATUS_CONTRACT = [
	{
		label: 'ATTIVATO',
		code: 'ATTIVATO'
	},
	{
		label: 'IN LAVORAZIONE',
		code: 'IN LAVORAZIONE'
	},
	{
		label: 'INSERITO FORNITORE',
		code: 'INSERITO FORNITORE'
	},
	{
		label: 'KO INTERNO',
		code: 'KO INTERNO'
	},
	{
		label: 'KO FORNITORE',
		code: 'KO FORNITORE'
	},
	{
		label: 'OBSOLETO',
		code: 'OBSOLETO'
	},
	{
		label: 'RECEDUTO',
		code: 'RECEDUTO'
	},
	{
		label: 'VOLTURATO',
		code: 'VOLTURATO'
	}
];
export const ENUM_SUPPLY = [
	{
		label: 'POD',
		code: 'POD'
	},
	{
		label: 'PDR',
		code: 'PDR'
	}
];
export const ENUM_PERCENTAGE_OFF = [
	{
		label: '110%',
		code: '110'
	},
	{
		label: '50/65%',
		code: '50/65'
	}
];

export const ENUM_PRECONTRACT_STATUS_PIPELINE = [
	{
		label: 'APPROVATO',
		code: 'S'
	},
	{
		label: 'RIFIUTATO',
		code: 'F'
	},
	{
		label: 'SOSPESO',
		code: 'H'
	},
	{
		label: 'IN LAVORAZIONE',
		code: 'W'
	},
	{
		label: 'DA LAVORARE',
		code: 'WORKING'
	}
];
export const ENUM_INSPECTION_STATUS_PIPELINE = [
	{
		label: 'IN LAVORAZIONE',
		code: 'W'
	},
	{
		label: 'COMPLETATO',
		code: 'S'
	},
	{
		label: 'DA LAVORARE',
		code: 'WORKING'
	}
];
export const ENUM_INSPECTION = [
	{
		code: 'verifica-documento',
		label: 'VERIFICA DOCUMENTO',
		status: [
			{
				label: 'IN LAVORAZIONE',
				code: 'W'
			},
			{
				label: 'COMPLETATO',
				code: 'S'
			}
		]
	},
	{
		code: 'verifica-tecnica',
		label: 'VERIFICA TECNICA',
		status: [
			{
				label: 'IN LAVORAZIONE',
				code: 'W'
			},
			{
				label: 'COMPLETATO',
				code: 'S'
			}
		]
	},
	{
		code: 'firma-pre-contratto',
		label: 'FIRMA PRE CONTRATTO',
		status: [
			{
				label: 'IN LAVORAZIONE',
				code: 'W'
			},
			{
				label: 'COMPLETATO',
				code: 'S'
			}
		]
	},
	{
		code: 'ape-smart',
		label: 'APE SMART',
		status: [
			{
				label: 'IN LAVORAZIONE',
				code: 'W'
			},
			{
				label: 'COMPLETATO',
				code: 'S'
			}
		]
	},
	{
		code: 'firma-contratto',
		label: 'FIRMA CONTRATTO',
		status: [
			{
				label: 'IN LAVORAZIONE',
				code: 'W'
			},
			{
				label: 'COMPLETATO',
				code: 'S'
			}
		]
	},
	{
		code: 'realizzazione',
		label: 'REALIZZAZIONE',
		status: [
			{
				label: 'IN LAVORAZIONE',
				code: 'W'
			},
			{
				label: 'COMPLETATO',
				code: 'S'
			}
		]
	}
];
export const TYPES_PARTNER = [
	{
		label: 'Nome',
		name: 'name',
		type: 'text'
	},
	{
		label: 'Partita IVA',
		name: 'vatCode',
		type: 'text'
	},
	{
		label: 'Codice fiscale',
		name: 'fiscalCode',
		type: 'text'
	}
];
export const TYPES_CUSTOMER = [
	{
		label: 'Nome',
		name: 'personFirstName',
		type: 'text'
	},
	{
		label: 'Cognome',
		name: 'personLastName',
		type: 'text'
	},
	{
		label: 'Collegato a',
		name: 'someParent',
		type: 'enum',
		values: [
			{ code: 'NO', label: 'Nessuno' },
			{ code: 'SI', label: 'Qualcuno' }
		]
	},
	{
		label: 'Denominazione Cliente',
		name: 'name',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Tipologia Cliente',
		name: 'type',
		type: 'enum',
		values: [
			{ code: 'PG', label: 'AZIENDA' },
			{ code: 'PF', label: 'PERSONA FISICA' },
			{ code: 'AC', label: 'AMM. CONDOMINIO' },
			{ code: 'CC', label: 'CONDOMINIO' },
			{ code: 'SA', label: 'STUDIO AMM.CONDOMINIO' }
		]
	},
	{
		label: 'Partita IVA',
		name: 'vatCode',
		type: 'text'
	},
	{
		label: 'Codice fiscale',
		name: 'fiscalCode',
		type: 'text'
	},
	{
		label: 'Città',
		name: 'legalPlace',
		type: 'text'
	},
	{
		label: 'Telef./Cell.',
		name: 'phoneNumber',
		type: 'text'
	},
	{
		label: 'Email',
		name: 'email',
		type: 'text'
	},
	{
		label: 'Agenzia',
		name: 'agencyName',
		type: 'text'
	},
	{
		label: 'Agente',
		name: 'assignedAgent',
		type: 'text'
	},
	{
		label: 'Data inserimento',
		name: 'created',
		type: 'date'
	},
	{
		label: 'Rappr. Legale',
		name: 'legalContactPerson',
		type: 'text'
	},
	{
		label: 'Codice Cliente',
		name: 'code',
		type: 'text'
	}
];

export const TYPES_CUSTOMER_AGENCYTYPE = [
	{
		label: 'Nome',
		name: 'personFirstName',
		type: 'text'
	},
	{
		label: 'Cognome',
		name: 'personLastName',
		type: 'text'
	},
	{
		label: 'Collegato a',
		name: 'someParent',
		type: 'enum',
		values: [
			{ code: 'NO', label: 'Nessuno' },
			{ code: 'SI', label: 'Qualcuno' }
		]
	},
	{
		label: 'Nickname',
		name: 'nickname',
		type: 'text'
	},
	{
		label: 'Denominazione Cliente',
		name: 'name',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Tipologia Cliente',
		name: 'type',
		type: 'enum',
		values: [
			{ code: 'PG', label: 'AZIENDA' },
			{ code: 'PF', label: 'PERSONA FISICA' },
			{ code: 'AC', label: 'AMM. CONDOMINIO' },
			{ code: 'CC', label: 'CONDOMINIO' },
			{ code: 'SA', label: 'STUDIO AMM.CONDOMINIO' }
		]
	},
	{
		label: 'Da verificare',
		name: 'toBeCheck',
		type: 'enum',
		values: ENUM_TOBECHECK,
		defaultOperator: 'like'
	},
	{
		label: 'Assistenza',
		name: 'customerService',
		type: 'enum',
		values: ENUM_TOBECHECK,
		defaultOperator: 'like'
	},
	{
		label: 'Partita IVA',
		name: 'vatCode',
		type: 'text'
	},
	{
		label: 'Codice fiscale',
		name: 'fiscalCode',
		type: 'text'
	},
	{
		label: 'Città',
		name: 'legalPlace',
		type: 'text'
	},
	{
		label: 'Telef./Cell.',
		name: 'phoneNumber',
		type: 'text'
	},
	{
		label: 'Agenzia/Agente',
		name: 'assignedAgent',
		type: 'text'
	},
	{
		label: 'Data inserimento',
		name: 'created',
		type: 'date'
	},
	{
		label: 'Data modifica',
		name: 'modified',
		type: 'date'
	},
	{
		label: 'Classificazione',
		name: 'classification',
		type: 'text'
	},
	{
		label: 'Appuntamento terminato',
		name: 'lastTask',
		type: 'date'
	},
	{
		label: 'Privacy',
		name: 'privacy',
		type: 'enum',
		values: [
			{ code: 'OK', label: 'Accettata' },
			{ code: 'KO', label: 'Respinta' },
			{ code: 'vuoto', label: 'vuoto' }
		]
	},
	{
		label: 'Rappr. Legale',
		name: 'legalContactPerson',
		type: 'text'
	},
	{
		label: 'Ultima variazione stato',
		name: 'lastStatusModification',
		type: 'date'
	},
	{
		label: 'Stato',
		name: 'status',
		type: 'enum',
		values: [
			{
				label: 'CESSATO',
				code: 'CESSATO'
			},
			{
				label: 'CLIENTE',
				code: 'CLIENTE'
			},
			{
				label: 'CONTATTO',
				code: 'CONTATTO'
			},
			{
				label: 'EX CLIENTE',
				code: 'EX CLIENTE'
			},
			{
				label: 'LEAD',
				code: 'LEAD'
			}
		]
	},
	{
		label: 'Codice Cliente',
		name: 'code',
		type: 'text'
	}
];


export const TYPES_LEADS = [
	{
		label: 'Nome',
		name: 'firstName',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Cognome',
		name: 'lastName',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Ragione Sociale',
		name: 'companyName',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Creato da',
		name: 'createdBy',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Cliente Creato',
		name: 'name',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Data inserimento',
		name: 'created',
		type: 'date'
	},
	{
		label: 'Data modifica',
		name: 'modified',
		type: 'date'
	},
	{
		label: 'Stato',
		name: 'status',
		type: 'enum',
		values: ENUM_STATUS_LEADS
	},
	{
		label: 'Tag',
		name: 'tag',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Tipo',
		name: 'type',
		type: 'enum',
		values: ENUM_TYPE_LEADS
	},
	{
		label: 'Assistenza',
		name: 'customerService',
		type: 'enum',
		values: ENUM_TOBECHECK,
		defaultOperator: 'like'
	}
];

export const TYPES_CAMPAIGNS = [
	{
		label: 'Tipologia campagna',
		name: 'campaignTypology',
		type: 'text',
		defaultOperator: 'like'
	},
	{
		label: 'Agente',
		name: 'agent',
		type: 'text',
		defaultOperator: 'like'
	},
	{
		label: 'Nome',
		name: 'name',
		type: 'text',
		defaultOperator: 'like'
	},
	{
		label: 'Descrizione',
		name: 'description',
		type: 'text',
		defaultOperator: 'like'
	},
	{
		label: 'Data inizio campagna',
		name: 'campaignBeginDate',
		type: 'date'
	},
	{
		label: 'Data fine campagna',
		name: 'campaignEndDate',
		type: 'date'
	},
	{
		label: 'Data soglia scadenza',
		name: 'campaignExpiryDate',
		type: 'date'
	},
	{
		label: 'Fornitore',
		name: 'campaignSupplier',
		type: 'text',
		defaultOperator: 'like'
	},
	{
		label: 'Stato',
		name: 'campaignStatus',
		type: 'enum',
		values: ENUM_STATUS_CAMPAIGNS
	},
	{
		label: 'Superata scadenza',
		name: 'expired',
		type: 'enum',
		values: [
			{
				label: 'SI',
				code: 'Y'
			},
			{
				label: 'NO',
				code: 'N'
			}
		]
	}
];

export const TYPES_CAMPAIGN_ALL_ELEMS = [
	{
		label: 'Tipologia campagna',
		name: 'campaignTypology',
		type: 'text',
		defaultOperator: 'like'
	},
	{
		label: 'Agente',
		name: 'agent',
		type: 'text',
		defaultOperator: 'like'
	},
	{
		label: 'Prossima Chiamata',
		name: 'nextCallDate',
		type: 'date'
	},
	{
		label: 'Nome Campagna',
		name: 'campaign',
		type: 'text',
		defaultOperator: 'like',
		default: true
	},
	{
		label: 'Nome Cliente',
		name: 'bpartnerName',
		type: 'text',
		defaultOperator: 'like'
	},
	{
		label: 'Nome Potenziale Cliente',
		name: 'leadName',
		type: 'text',
		defaultOperator: 'like'
	},
	{
		label: 'Indirizzo Cliente',
		name: 'address',
		type: 'text',
		defaultOperator: 'like'
	},
	{
		label: 'Indirizzo Cliente (Comune)',
		name: 'city',
		type: 'text',
		defaultOperator: 'like'
	},
	{
		label: 'Indirizzo Cliente (Provincia)',
		name: 'province',
		type: 'text',
		defaultOperator: 'like'
	},
	{
		label: 'Indirizzo Cliente (CAP)',
		name: 'cap',
		type: 'text',
		defaultOperator: 'like'
	},
	{
		label: 'Esito Azione',
		name: 'status',
		type: 'enum',
		values: ENUM_STATUS_CAMPAIGN_ELEMS
	},
	{
		label: 'Stato Campagna',
		name: 'campaignStatus',
		type: 'enum',
		values: ENUM_STATUS_CAMPAIGNS
	},
	{
		label: 'Superata Scadenza',
		name: 'expired',
		type: 'enum',
		values: [
			{
				label: 'SI',
				code: 'Y'
			},
			{
				label: 'NO',
				code: 'N'
			}
		]
	},
	{
		label: 'Data Soglia Scadenza',
		name: 'campaignExpiryDate',
		type: 'date'
	},
	{
		label: 'C.F. Cliente',
		name: 'fiscalCode',
		type: 'text',
		defaultOperator: 'like'
	},
	{
		label: 'C.F. Potenziale Cliente',
		name: 'fiscalCodeLead',
		type: 'text',
		defaultOperator: 'like'
	},
	{
		label: 'P.IVA Cliente',
		name: 'vatCode',
		type: 'text',
		defaultOperator: 'like'
	},
	{
		label: 'P.IVA Potenziale Cliente',
		name: 'vatCodeLead',
		type: 'text',
		defaultOperator: 'like'
	},
	{
		label: 'Classificazione Cliente',
		name: 'classification',
		type: 'text'
	},
	{
		label: 'Fornitore',
		name: 'campaignSupplier',
		type: 'text',
		defaultOperator: 'like'
	}
];

export const TYPES_CAMPAIGN_ELEMS = [
	{
		label: 'Stato',
		name: 'status',
		type: 'enum',
		values: ENUM_STATUS_CAMPAIGN_ELEMS
	},
	{
		label: 'Prossima Chiamata',
		name: 'nextCallDate',
		type: 'date'
	},
	{
		label: 'Nome Cliente',
		name: 'bpartnerName',
		type: 'text',
		defaultOperator: 'like'
	},
	{
		label: 'Nome Potenziale cliente',
		name: 'leadName',
		type: 'text',
		defaultOperator: 'like'
	},
	{
		label: 'Indirizzo Cliente',
		name: 'address',
		type: 'text',
		defaultOperator: 'like'
	},
	{
		label: 'C.F. Cliente',
		name: 'fiscalCode',
		type: 'text',
		defaultOperator: 'like'
	},
	{
		label: 'C.F. Potenziale Cliente',
		name: 'fiscalCodeLead',
		type: 'text',
		defaultOperator: 'like'
	},
	{
		label: 'P.IVA Cliente',
		name: 'vatCode',
		type: 'text',
		defaultOperator: 'like'
	},
	{
		label: 'P.IVA Potenziale Cliente',
		name: 'vatCodeLead',
		type: 'text',
		defaultOperator: 'like'
	},
	{
		label: 'Indirizzo Cliente (Comune)',
		name: 'city',
		type: 'text',
		defaultOperator: 'like'
	},
	{
		label: 'Indirizzo Cliente (Provincia)',
		name: 'province',
		type: 'text',
		defaultOperator: 'like'
	},
	{
		label: 'Indirizzo Cliente (CAP)',
		name: 'cap',
		type: 'text',
		defaultOperator: 'like'
	},
	{
		label: 'Classificazione Cliente',
		name: 'classification',
		type: 'text'
	}
];

export const TYPES_CAMPAIGN_BP_ELEMS = [
	{
		label: 'Nome Campagna',
		name: 'campaign',
		type: 'text',
		defaultOperator: 'like',
		default: true
	},
	{
		label: 'Esito Azione',
		name: 'status',
		type: 'enum',
		values: ENUM_STATUS_CAMPAIGN_ELEMS
	},
	{
		label: 'Superata Scadenza',
		name: 'expired',
		type: 'enum',
		values: [
			{
				label: 'SI',
				code: 'Y'
			},
			{
				label: 'NO',
				code: 'N'
			}
		]
	},
	{
		label: 'Data Soglia Scadenza',
		name: 'campaignExpiryDate',
		type: 'date'
	}
];

export const TYPES_OPPORTUNITY = [
	{
		label: 'Stato',
		name: 'status',
		type: 'enum',
		values: ENUM_STATUS_OPPORTUNITY
	},
	{
		label: 'P.IVA',
		name: 'vat',
		type: 'text'
	},
	{
		label: 'Agente/Agenzia cliente',
		name: 'agent',
		type: 'text'
	},
	{
		label: 'Nome Cliente',
		name: 'customer',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Data inserimento',
		name: 'created',
		type: 'date'
	},
	{
		label: 'Data modifica',
		name: 'modified',
		type: 'date'
	}
];

export const TYPES_PRECONTRACT = [
	{
		label: 'ID',
		name: 'id',
		type: 'text'
	},
	{
		label: 'Agenzia',
		name: 'agency',
		type: 'text'
	},
	{
		label: 'P.IVA',
		name: 'vat',
		type: 'text'
	},
	{
		label: 'Agente',
		name: 'agent',
		type: 'text'
	},
	{
		label: 'Nome Cliente',
		name: 'customer',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Data inserimento',
		name: 'created',
		type: 'date'
	},
	/*{
		label: 'Data modifica',
		name: 'modified',
		type: 'date'
	},*/
	{
		label: 'Tipologia Pre-Contratto',
		name: 'conclStartType',
		type: 'enum',
		values: [
			{ code: 'NUOVO ALLACCIO', label: 'NUOVO ALLACCIO' },
			{
				code: 'ALLACCIO E CONT',
				label: 'NUOVO ALLACCIO E POSA CONTATORE'
			},
			{ code: 'SUBENTRO', label: 'SUBENTRO' },
			{ code: 'SWITCH-IN', label: 'SWITCH-IN' },

			{ code: 'SWITCH-VOLT-O', label: 'SWITCH-IN CON VOLTURA ORDINARIA' },
			{ code: 'SWITCH-VOLT-M', label: 'SWITCH-IN CON VOLTURA MORTIS CAUSA' },
			{ code: 'VOLTURA-O', label: 'VOLTURA ORDINARIA' },
			{ code: 'VOLTURA-M', label: 'VOLTURA MORTIS CAUSA' }
		]
	},
	{
		label: 'Tipologia Cliente',
		name: 'customerType',
		type: 'enum',		
		values: [
			{ code: 'B', label: 'BUSINESS' },
			{ code: 'D', label: 'DOMESTICI' },
			{ code: 'C', label: 'CONDOMINI' }
		]
	},
	{
		label: 'Stato Pre-Contratto',
		name: 'status',
		type: 'enum',
		values: ENUM_STATUS
	},
	{
		label: 'Stato workflow',
		name: 'statusWorkflow',
		type: 'enum',
		values: ENUM_STATUS_WORKFLOW
	},
	{
		label: 'POD',
		name: 'pod',
		type: 'text',
		defaultOperator: 'like'
	},
	{
		label: 'PDR',
		name: 'pdr',
		type: 'text',
		defaultOperator: 'like'
	},
	{
		label: 'Credit Check',
		name: 'credit-check',
		type: 'pipeline',
		values: ENUM_PRECONTRACT_STATUS_PIPELINE
	},
	{
		label: 'Quality Check',
		name: 'quality-check',
		type: 'pipeline',
		values: ENUM_PRECONTRACT_STATUS_PIPELINE
	},
	{
		label: 'Validazione',
		name: 'validazione',
		type: 'pipeline',
		values: ENUM_PRECONTRACT_STATUS_PIPELINE
	},
	{
		label: 'Switch in',
		name: 'switch-in',
		type: 'pipeline',
		values: ENUM_PRECONTRACT_STATUS_PIPELINE
	},
	/*
	{
		label: 'Welcome',
		name: 'welcome',
		type: 'pipeline',
		values: ENUM_PRECONTRACT_STATUS_PIPELINE
	}
	**/
];

export const TYPES_PRACTICE = [
	
	{
		label: 'POD/PDR',
		name: 'pod',
		type: 'text',
		defaultOperator: 'like'
	},
	{
		label: 'Agenzia',
		name: 'agency',
		type: 'text'
	},
	{
		label: 'Cliente',
		name: 'customer',
		type: 'text',
		defaultOperator: 'like'
	},
	{
		label: 'ID',
		name: 'id',
		type: 'text'
	},
	{
		label: 'Tipologia Pratica',
		name: 'typePractice',
		type: 'text'
	},
	{
		label: 'Data creazione',
		name: 'created',
		type: 'date'
	},
	{
		label: 'Operatore creazione',
		name: 'createdBy',
		type: 'text'
	},
	{
		label: 'Data ultima modifica',
		name: 'modified',
		type: 'date'
	},
	{
		label: '01 Validazione',
		name: 'validazione',
		type: 'pipeline',
		values: ENUM_PRECONTRACT_STATUS_PIPELINE
	},
	{
		label: '02 Caricamento Portale Distributore',
		name: 'caricamento-portale-distributore',
		type: 'pipeline',
		values: ENUM_PRECONTRACT_STATUS_PIPELINE
	},
	{
		label: '03 Emissione Preventivo',
		name: 'preventivo-distributore',
		type: 'pipeline',
		values: ENUM_PRECONTRACT_STATUS_PIPELINE
	},
	{
		label: '04 Accettazione Preventivo Cliente',
		name: 'accettazione-preventivo-cliente',
		type: 'pipeline',
		values: ENUM_PRECONTRACT_STATUS_PIPELINE
	},
	{
		label: '05 Emissione Fattura Oneri',
		name: 'fattura-di-soli-oneri',
		type: 'pipeline',
		values: ENUM_PRECONTRACT_STATUS_PIPELINE
	},
	{
		label: '06 Pagamento Fattura Oneri',
		name: 'pagamento-fattura-soli-oneri',
		type: 'pipeline',
		values: ENUM_PRECONTRACT_STATUS_PIPELINE
	},
	{
		label: '07 Accettazione Preventivo Trasporto',
		name: 'accettazione-preventivo-trasporto',
		type: 'pipeline',
		values: ENUM_PRECONTRACT_STATUS_PIPELINE
	},
	{
		label: '08 Appuntamento',
		name: 'appuntamento',
		type: 'pipeline',
		values: ENUM_PRECONTRACT_STATUS_PIPELINE
	},
	{
		label: '09 Evasione',
		name: 'evasione',
		type: 'pipeline',
		values: ENUM_PRECONTRACT_STATUS_PIPELINE
	}
/*
	{
		label: 'Agente',
		name: 'agent',
		type: 'text'
	},
	{
		label: 'Nome Sottoscrittore',
		name: 'subscriber',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Data inserimento',
		name: 'created',
		type: 'date'
	},
	{
		label: 'Data modifica',
		name: 'modified',
		type: 'date'
	},
	{
		label: 'Tipologia Cliente',
		name: 'customerType',
		type: 'enum',		
		values: [
			{ code: 'PF', label: 'PERSONA FISICA' },
			{ code: 'PG', label: 'PERSONA GIURIDICA' }
		]
	},
	{
		label: 'Stato Pratica',
		name: 'status',
		type: 'enum',
		values: ENUM_STATUS
	},
	{
		label: 'Stato workflow',
		name: 'statusWorkflow',
		type: 'enum',
		values: ENUM_STATUS_WORKFLOW
	},*/
];

export const TYPES_INSPECTION = [
	{
		label: 'Agenzia',
		name: 'agency',
		type: 'text'
	},
	{
		label: 'Agente',
		name: 'agent',
		type: 'text'
	},
	{
		label: 'Creato da',
		name: 'createdBy',
		type: 'text'
	},
	{
		label: 'Data inserimento',
		name: 'created',
		type: 'date'
	},
	{
		label: 'Data modifica',
		name: 'modified',
		type: 'date'
	},
	{
		label: 'Nome committente',
		name: 'customerFirstName',
		type: 'text'
	},
	{
		label: 'Cognome committente',
		name: 'customerLastName',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: '% Contributo',
		name: 'percentageOff',
		type: 'enum',
		values: ENUM_PERCENTAGE_OFF
	},
	{
		label: 'Stato',
		name: 'status',
		type: 'enum',
		values: ENUM_STATUS
	},
	{
		label: 'Tipologia immobile',
		name: 'typeImmo',
		type: 'enum',
		values: ENUM_TYPE_IMMO
	},
	{
		label: 'Verifica documento',
		name: 'verifica-documento',
		type: 'pipeline',
		values: ENUM_INSPECTION_STATUS_PIPELINE
	},
	{
		label: 'Verifica tecnica',
		name: 'verifica-tecnica',
		type: 'pipeline',
		values: ENUM_INSPECTION_STATUS_PIPELINE
	},
	{
		label: 'Firma pre-contratto',
		name: 'firma-pre-contratto',
		type: 'pipeline',
		values: ENUM_INSPECTION_STATUS_PIPELINE
	},
	{
		label: 'APE Smart',
		name: 'ape-smart',
		type: 'pipeline',
		values: ENUM_INSPECTION_STATUS_PIPELINE
	},
	{
		label: 'Firma contratto',
		name: 'firma-contratto',
		type: 'pipeline',
		values: ENUM_INSPECTION_STATUS_PIPELINE
	},
	{
		label: 'Realizzazione',
		name: 'realizzazione',
		type: 'pipeline',
		values: ENUM_INSPECTION_STATUS_PIPELINE
	}
];

export const TYPES_CONTRACT_AGENCYTYPE = [
	{
		label: 'N° Contratto',
		name: 'contractNo',
		type: 'text',
		customOperators: ['like', 'startWith']
	},
	{
		label: 'Tipo Fornitura',
		name: 'supply',
		type: 'enum',
		values: ENUM_SUPPLY
	},
	{
		label: 'POD',
		name: 'pod',
		type: 'text'
	},
	{
		label: 'PDR',
		name: 'pdr',
		type: 'text'
	},
	{
		label: 'Stato Lavorazione',
		name: 'workingStates',
		type: 'enum',
		values: ENUM_STATUS_CONTRACT
	},
	{
		label: 'Firmatario',
		name: 'signatory',
		type: 'text'
	},
	{
		label: 'Agenzia/Agente',
		name: 'sellerName',
		type: 'text'
	},
	{
		label: 'Denominazione Cliente',
		name: 'customerName',
		type: 'text'
	},
	{
		label: 'Partita IVA',
		name: 'piva',
		type: 'text'
	},
	{
		label: 'Codice fiscale',
		name: 'fiscalCode',
		type: 'text'
	},
	{
		label: 'Consumo annuo',
		name: 'contractedAnnualVolume',
		type: 'number'
	},
	{
		label: 'Data inserimento contratto',
		name: 'contractDateInsertUser',
		type: 'date'
	},
	{
		label: 'Data inserimento linea',
		name: 'created',
		type: 'date'
	},
	{
		label: 'Data modifica linea',
		name: 'modified',
		type: 'date'
	},
	{
		label: 'Data modifica provv.',
		name: 'modifiedCommissioning',
		type: 'date'
	},
	/*{
		label: 'Data stipula contratto',
		name: 'contractDate',
		type: 'date'
	},*/
	{
		label: 'Data Inizio Fornitura',
		name: 'startDate',
		type: 'date'
	},
	{
		label: 'Codice cliente',
		name: 'customerCode',
		type: 'text'
	},
	{
		label: 'Venditore',
		name: 'workspaceName',
		type: 'text'
	},
	{
		label: 'Assistenza',
		name: 'customerService',
		type: 'enum',
		values: ENUM_TOBECHECK,
		defaultOperator: 'like'
	},
	{
		label: 'Pagamento provvigione',
		name: 'feePaymentStatus',
		type: 'enum',
		values: ENUM_PAYMENT
	},
	{
		label: 'Listino',
		name: 'priceList',
		type: 'text'
	},
	{
		label: 'Tipologia (cliente)',
		name: 'typologyBPartner',
		type: 'enum',
		values: ENUM_TYPOLOGY_BPARTNER
	},
	{
		label: 'Tipologia clientela',
		name: 'customerType',
		type: 'enum',
		values: [
			{ code: 'B', label: 'BUSINESS' },
			{ code: 'D', label: 'DOMESTICI' },
			{ code: 'C', label: 'CONDOMINI' }
		]
	},
	{
		label: 'Provvigione (data)',
		name: 'dateCommission',
		type: 'date',
		defaultOperator: '=',
		customOperators: ['=']
	},
	{
		label: 'Tipo Listino',
		name: 'priceListType',
		type: 'enum',
		values: [
			{ code: 'F', label: 'FISSO' },
			{ code: 'V', label: 'VARIABILE' }
		]
	}
];

export const TYPES_CONTRACT = [
	{
		label: 'N° Contratto',
		name: 'contractNo',
		type: 'text',
		customOperators: ['like', 'startWith']
	},
	{
		label: 'Tipo Fornitura',
		name: 'supply',
		type: 'enum',
		values: ENUM_SUPPLY
	},
	{
		label: 'POD',
		name: 'pod',
		type: 'text'
	},
	{
		label: 'PDR',
		name: 'pdr',
		type: 'text'
	},
	{
		label: 'Agenzia/Agente',
		name: 'sellerName',
		type: 'text'
	},
	{
		label: 'Denominazione Cliente',
		name: 'customerName',
		type: 'text'
	},
	{
		label: 'Partita IVA',
		name: 'piva',
		type: 'text'
	},
	{
		label: 'Codice fiscale',
		name: 'fiscalCode',
		type: 'text'
	},
	{
		label: 'Data inserimento contratto',
		name: 'contractDateInsertUser',
		type: 'date'
	},
	{
		label: 'Data inserimento linea',
		name: 'created',
		type: 'date'
	},
	{
		label: 'Data modifica linea',
		name: 'modified',
		type: 'date'
	},
	{
		label: 'Data stipula contratto',
		name: 'contractDate',
		type: 'date'
	},
	{
		label: 'Data Inizio Fornitura',
		name: 'startDate',
		type: 'date'
	},
	{
		label: 'Codice cliente',
		name: 'customerCode',
		type: 'text'
	},
	{
		label: 'Tipologia (cliente)',
		name: 'typologyBPartner',
		type: 'enum',
		values: ENUM_TYPOLOGY_BPARTNER
	},
	{
		label: 'Tipologia clientela',
		name: 'customerType',
		type: 'enum',
		values: [
			{ code: 'B', label: 'BUSINESS' },
			{ code: 'D', label: 'DOMESTICI' },
			{ code: 'C', label: 'CONDOMINI' }
		]
	}
];

export const TYPES_CONTRACT_NOLINES_AGENCYTYPE = [
	{
		label: 'N° Contratto',
		name: 'contractNo',
		type: 'text',
		customOperators: ['like', 'startWith']
	},	
	{
		label: 'Tipo Fornitura',
		name: 'supply',
		type: 'enum',
		values: ENUM_SUPPLY
	},
	{
		label: 'Stato Lavorazione',
		name: 'workingStates',
		type: 'enum',
		values: ENUM_STATUS_CONTRACT
	},
	{
		label: 'Firmatario',
		name: 'signatory',
		type: 'text'
	},
	{
		label: 'Agenzia/Agente',
		name: 'sellerName',
		type: 'text'
	},
	{
		label: 'Denominazione Cliente',
		name: 'customerName',
		type: 'text'
	},
	{
		label: 'Partita IVA',
		name: 'piva',
		type: 'text'
	},
	{
		label: 'Codice fiscale',
		name: 'fiscalCode',
		type: 'text'
	},
	{
		label: 'Data inserimento contratto',
		name: 'contractDateInsertUser',
		type: 'date'
	},
	{
		label: 'Data stipula contratto',
		name: 'contractDate',
		type: 'date'
	},
	{
		label: 'Data Inizio Fornitura',
		name: 'startDate',
		type: 'date'
	},
	{
		label: 'Codice cliente',
		name: 'customerCode',
		type: 'text'
	},
	{
		label: 'Venditore',
		name: 'workspaceName',
		type: 'text'
	},
	{
		label: 'Assistenza',
		name: 'customerService',
		type: 'enum',
		values: ENUM_TOBECHECK,
		defaultOperator: 'like'
	},
	{
		label: 'Tipologia (cliente)',
		name: 'typologyBPartner',
		type: 'enum',
		values: ENUM_TYPOLOGY_BPARTNER
	},
];

export const TYPES_CONTRACT_NOLINES = [
	{
		label: 'N° Contratto',
		name: 'contractNo',
		type: 'text',
		customOperators: ['like', 'startWith']
	},	
	{
		label: 'Tipo Fornitura',
		name: 'supply',
		type: 'enum',
		values: ENUM_SUPPLY
	},
	{
		label: 'Agenzia/Agente',
		name: 'sellerName',
		type: 'text'
	},
	{
		label: 'Denominazione Cliente',
		name: 'customerName',
		type: 'text'
	},
	{
		label: 'Partita IVA',
		name: 'piva',
		type: 'text'
	},
	{
		label: 'Codice fiscale',
		name: 'fiscalCode',
		type: 'text'
	},
	{
		label: 'Data stipula contratto',
		name: 'contractDate',
		type: 'date'
	},
	{
		label: 'Data Inizio Fornitura',
		name: 'startDate',
		type: 'date'
	},
	{
		label: 'Codice cliente',
		name: 'customerCode',
		type: 'text'
	},
	{
		label: 'Tipologia (cliente)',
		name: 'typologyBPartner',
		type: 'enum',
		values: ENUM_TYPOLOGY_BPARTNER
	},
];

export const TYPES_CONTACT = [
	{
		label: 'Nome',
		name: 'firstName',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Telefono',
		name: 'phoneNo1',
		type: 'text'
	},
	{
		label: 'Cellulare',
		name: 'cellNo1',
		type: 'text'
	},
	{
		label: 'Email',
		name: 'email',
		type: 'text'
	}
];

export const TYPES_INVOICE = [
	{
		label: 'Numero Fattura',
		name: 'no',
		type: 'text'
	},
	{
		label: 'Cliente',
		name: 'bpartnerName',
		type: 'text'
	},
	{
		label: 'Numero Contratto',
		name: 'contractNo',
		type: 'text'
	},
	/*{
		label: 'Data emissione',
		name: 'invoiceDate',
		type: 'date'
	},*/
	{
		label: 'Data emissione da',
		name: 'invoiceDateFrom',
		type: 'date',
		customOperators: ['>=']
	},
	{
		label: 'Data emissione a',
		name: 'invoiceDateTo',
		type: 'date',
		customOperators: ['<=']
	},/*
	{
		label: 'Periodo Emissione',
		name: 'period',
		type: 'enum',
		values: [
			{
				label: 'Ultimi 30 giorni',
				code: 'lastMonth'
			},
			{
				label: 'Ultimi 90 giorni',
				code: 'last3Month',
				//default: true
			}
		],
		//default: true,
		//defaultOperator: '='
	},*/
	{
		label: 'Data scadenza',
		name: 'invoiceExpireDate',
		type: 'date'
	},
	{
		label: 'Stato',
		name: 'status',
		type: 'enum',
		values: ENUM_INVOICE_STATUS
	},
	{
		label: 'Tipologia Fattura',
		name: 'invoiceType',
		type: 'enum',
		values: [
			{
				code: 'G',
				label: 'Gas'
			},
			{
				code: 'E',
				label: 'Energia'
			}
		]
	},
];

export const TYPES_ACTIVITY = [
	{
		label: 'Titolo',
		name: 'title',
		type: 'text'
	},
	{
		label: 'Assegnatario',
		name: 'assegnee',
		type: 'text'
	},
	{
		label: 'Tipo',
		name: 'tipology',
		type: 'enum',
		values: [
			{
				code: 'apt',
				label: 'Appuntamento'
			},
			{
				code: 'tkt',
				label: 'Ticket'
			},
			{
				code: 'chat',
				label: 'Chat'
			}
		]
	},
	{
		label: 'Stato',
		name: 'status',
		type: 'enum',
		values: [
			{
				label: 'IN LAVORAZIONE',
				code: 'work'
			},
			{
				label: 'TERMINATO',
				code: 'end'
			},
			{
				label: 'BOZZA',
				code: 'draft'
			}
		]
	}
];

export const TYPES_AGENT = [
	{
		label: 'Nome',
		name: 'personFirstname',
		type: 'text'
	},
	{
		label: 'Cognome',
		name: 'personLastname',
		type: 'text'
	},
	{
		label: 'Codice fiscale',
		name: 'fiscalCode',
		type: 'text'
	},
	{
		label: 'Partita IVA',
		name: 'vatCode',
		type: 'text'
	},
	{
		label: 'Nome completo',
		name: 'name',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	}
];

export const TYPES_AGENCY = [
	{
		label: 'Nome',
		name: 'name',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	,
	{
		label: 'Codice fiscale',
		name: 'fiscalCode',
		type: 'text'
	},
	{
		label: 'Partita IVA',
		name: 'vatCode',
		type: 'text'
	}
];

export const TYPES_POD_PDR = [
	{
		label: 'Codice',
		name: 'podPdr',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'N° Contratto',
		name: 'contractNo',
		type: 'text'
	},
	{
		label: 'Nome cliente',
		name: 'name',
		type: 'text'
	},
	{
		label: 'Codice cliente',
		name: 'code',
		type: 'text'
	},
	{
		label: 'Codice fiscale cliente',
		name: 'fiscalCode',
		type: 'text'
	},
	{
		label: 'Partita IVA cliente',
		name: 'vatCode',
		type: 'text'
	}
];

export const TYPES_PRICE_LIST = [
	{
		label: 'Sistema',
		name: 'energyType',
		type: 'enum',
		values: [
			{
				label: 'Energia',
				code: 'E'
			},
			{
				label: 'Gas',
				code: 'G'
			}
		]
	},
	{
		label: 'Tipo',
		name: 'priceListType',
		type: 'text'
	},
	{
		label: 'Venditore',
		name: 'workspaceName',
		type: 'text'
	},
	{
		label: 'Nome',
		name: 'priceList',
		type: 'text'
	},
	{
		label: 'Stato',
		name: 'status',
		type: 'enum',
		values: [
			{
				label: 'Attivo',
				code: 'active',
				default: true
			},
			{
				label: 'Scaduto',
				code: 'inactive'
			}
		],
		default: true,
		defaultOperator: '='
	}
];

export const TYPES_OFFER = [
	{
		label: 'Sistema',
		name: 'cfgSystem',
		type: 'enum',
		values: [
			{
				label: 'Energia',
				code: 'E'
			},
			{
				label: 'Gas',
				code: 'G'
			}
		]
	},
	{
		label: 'Tipol. Clientela',
		name: 'customerType',
		type: 'enum',
		values: [
			{
				label: 'Domestici',
				code: 'D'
			},
			{
				label: 'Business',
				code: 'B'
			},
			{
				label: 'Condomini',
				code: 'C'
			}
		]
	},
	{
		label: 'Nome',
		name: 'name',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	}
];

export const TYPES_TICKET_AGENCY_STYLE = [
	{
		label: 'Stato',
		name: 'status',
		type: 'enum',
		values: [
			{
				code: 'OPEN',
				label: 'APERTO'
			},
			{
				code: 'CLOSE',
				label: 'CHIUSO'
			},
			{
				code: 'DRAFT',
				label: 'BOZZA'
			}
		]
	},
	{
		label: 'Assegnazione',
		name: 'assign',
		type: 'enum',
		values: [
			{
				label: 'Assegnati a me',
				code: 'me'
			},
			{
				label: 'Tutte le assegnazioni',
				code: 'all'
			}
		]
	},
	{
		label: 'Cognome (Assegnatario)',
		name: 'lastNameAss',
		type: 'text'
	},
	{
		label: 'Nome (Assegnatario)',
		name: 'firstNameAss',
		type: 'text'
	},
	{
		label: 'Cognome (Reporter)',
		name: 'lastNameRep',
		type: 'text'
	},
	{
		label: 'Nome (Reporter)',
		name: 'firstNameRep',
		type: 'text'
	},
	{
		label: 'Descrizione',
		name: 'description',
		type: 'text'
	},
	{
		label: 'Workflow',
		name: 'storyboard.name',
		type: 'text'
	},
	{
		label: 'Emesso dal Reparto',
		name: 'workspace.name',
		type: 'text'
	},
	{
		label: 'Assegnato al Reparto',
		name: 'assignWorkspaceName',
		type: 'text'
	},
	{
		label: 'Scaduti',
		name: 'expired',
		type: 'enum',
		values: [
			{
				label: 'SI',
				code: true
			}
		]
	},
	{
		label: 'Data creazione',
		name: 'created',
		type: 'date'
	},
	{
		label: 'Data scadenza',
		name: 'expireDate',
		type: 'date'
	},
	{
		label: 'Data modifica',
		name: 'modified',
		type: 'date'
	},
	{
		label: 'Tipologia',
		name: 'typeName',
		type: 'text'
	},
	{
		label: 'Cliente',
		name: 'name',
		type: 'text'
	},
	{
		label: 'Numero Ticket',
		name: 'id',
		type: 'text'
	}
];

export const TYPES_TICKET = [
	{
		label: 'Stato',
		name: 'status',
		type: 'enum',
		values: [
			{
				code: 'OPEN',
				label: 'APERTO'
			},
			{
				code: 'CLOSE',
				label: 'CHIUSO'
			},
			{
				code: 'DRAFT',
				label: 'BOZZA'
			}
		]
	},
	{
		label: 'Assegnazione',
		name: 'assign',
		type: 'enum',
		values: [
			{
				label: 'Assegnati a me',
				code: 'me'
			},
			{
				label: 'Tutte le assegnazioni',
				code: 'all'
			}
		]
	},

	{
		label: 'Stato lavorazione',
		name: 'workStatus',
		type: 'enum',
		values: [
			{
				label: 'Da lavorare',
				code: 'toWork'
			},
			{
				label: 'Presi in carico',
				code: 'inProgress'
			},
			{
				label: 'Tutti',
				code: 'all'
			},
		]
	},

	{
		label: 'Cognome (Assegnatario)',
		name: 'lastNameAss',
		type: 'text'
	},
	{
		label: 'Nome (Assegnatario)',
		name: 'firstNameAss',
		type: 'text'
	},
	{
		label: 'Cognome (Reporter)',
		name: 'lastNameRep',
		type: 'text'
	},
	{
		label: 'Nome (Reporter)',
		name: 'firstNameRep',
		type: 'text'
	},
	{
		label: 'Descrizione',
		name: 'description',
		type: 'text'
	},
	{
		label: 'Workflow',
		name: 'storyboard.name',
		type: 'text'
	},
	{
		label: 'Emesso dal Reparto',
		name: 'workspace.name',
		type: 'text'
	},
	{
		label: 'Assegnato al Reparto',
		name: 'assignWorkspaceName',
		type: 'text'
	},
	{
		label: 'Scaduti',
		name: 'expired',
		type: 'enum',
		values: [
			{
				label: 'SI',
				code: true
			}
		]
	},
	{
		label: 'Data creazione',
		name: 'created',
		type: 'date'
	},

	{
		label: 'Mese creazione',
		name: 'monthCreated',
		type: 'enum',
		values: [
			{
				label: 'Gennaio',
				code: 1
			},
			{
				label: 'Febbraio',
				code: 2
			},
			{
				label: 'Marzo',
				code: 3
			},
			{
				label: 'Aprile',
				code: 4
			},
			{
				label: 'Maggio',
				code: 5
			},
			{
				label: 'Giugno',
				code: 6
			},
			{
				label: 'Luglio',
				code: 7
			},
			{
				label: 'Agosto',
				code: 8
			},
			{
				label: 'Settembre',
				code: 9
			},
			{
				label: 'Ottobre',
				code: 10
			},
			{
				label: 'Novembre',
				code: 11
			},
			{
				label: 'Dicembre',
				code: 12
			}
		]
	},

	{
		label: 'Anno creazione',
		name: 'yearCreated',
		type: 'text'
	},

	{
		label: 'Data scadenza',
		name: 'expireDate',
		type: 'date'
	},
	{
		label: 'Data modifica',
		name: 'modified',
		type: 'date'
	},
	{
		label: 'Tipologia',
		name: 'typeName',
		type: 'text'
	},
	{
		label: 'Cliente',
		name: 'name',
		type: 'text'
	},
	{
		label: 'Numero Ticket',
		name: 'id',
		type: 'text'
	},
	{
		label: 'Agente',
		name: 'agentName',
		type: 'text'
	},
	{
		label: 'Agenzia',
		name: 'agencyName',
		type: 'text'
	}
];

export const TYPES_TODO = [
	{
		label: 'Stato',
		name: 'status',
		type: 'enum',
		values: [
			{
				code: 'work',
				label: 'APERTO'
			},
			{
				code: 'end',
				label: 'COMPLETATO'
			}
		]
	},
	{
		label: 'Titolo',
		name: 'title',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Cognome (Assegnatario)',
		name: 'lastNameAss',
		type: 'text'
	},
	{
		label: 'Nome (Assegnatario)',
		name: 'firstNameAss',
		type: 'text'
	},
	{
		label: 'Cognome (Reporter)',
		name: 'lastNameRep',
		type: 'text'
	},
	{
		label: 'Nome (Reporter)',
		name: 'firstNameRep',
		type: 'text'
	},
	{
		label: 'Descrizione',
		name: 'description',
		type: 'text'
	},
	{
		label: 'Workflow',
		name: 'storyboard.name',
		type: 'text'
	},
	{
		label: 'Nome Reparto',
		name: 'workspace.name',
		type: 'text'
	},
	{
		label: 'Data creazione',
		name: 'created',
		type: 'date'
	},
	{
		label: 'Data modifica',
		name: 'modified',
		type: 'date'
	},
	{
		label: 'Cliente',
		name: 'customer',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	}
];

export const TYPES_FUNCTIONDEFINITION = [
	{
		label: 'Descrizione',
		name: 'description',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Chiave',
		name: 'key',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	}
];

export const TYPES_GROUPS = [
	{
		label: 'Nome',
		name: 'name',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	}
];

export const TYPES_USER = [
	{
		label: 'Nome',
		name: 'name',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Email',
		name: 'mail',
		type: 'text'
	},
	{
		label: 'UserId',
		name: 'id',
		type: 'text'
	},
	{
		label: 'Gruppo',
		name: 'group',
		type: 'text'
	},/*
	{ // era pensato per il campo ROLEINFO della USR_USERS
		label: 'Ruolo',
		name: 'auth',
		type: 'enum',
		values: [
			{ code: 'ROLE_USER', label: 'Utente' },
			{ code: 'ROLE_ADMINISTRATOR', label: 'Amministratore di sistema' },
			{ code: 'ROLE_SUPERVISOR', label: 'Supervisore di sistema' },
			{ code: 'ROLE_AGENCY', label: 'Ruolo Agenzia' },
			{ code: 'ROLE_AGENT', label: 'Ruolo Agente' },
			{ code: 'ROLE_BACKOFFICE', label: 'Ruolo Backoffice' }
		]
	},*/
	{
		label: 'Stato',
		name: 'enabled',
		type: 'enum',
		values: [
			{
				label: 'ATTIVO',
				code: true
			},
			{
				label: 'NON ATTIVO',
				code: false
			}
		]
	}
];

export const TYPES_TAG = [
	{
		label: 'Nome',
		name: 'name',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	}
];

export const TYPES_TEMPLATE = [
	{
		label: 'Nome',
		name: 'name',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	}
];

export const TYPES_TEMPLATE_MESSAGE = [
	{
		label: 'Titolo',
		name: 'title',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Messaggio',
		name: 'body',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Mailbox',
		name: 'mailbox.name',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	}
];

export const TYPES_REPORT_DEFINITIONS = [
	{
		label: 'Codice',
		name: 'code',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Nome',
		name: 'name',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Data inserimento',
		name: 'created',
		type: 'date'
	},
	{
		label: 'Data modifica',
		name: 'modified',
		type: 'date'
	},
	{
		label: 'Creato da',
		name: 'createdBy',
		type: 'text'
	},
	{
		label: 'Modificato da',
		name: 'modifiedBy',
		type: 'text'
	}
];

export const TYPES_REPORT_PARAMETERS = [
	{
		label: 'Codice',
		name: 'code',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Nome',
		name: 'name',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Definizione',
		name: 'definition',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Data inserimento',
		name: 'created',
		type: 'date'
	},
	{
		label: 'Data modifica',
		name: 'modified',
		type: 'date'
	},
	{
		label: 'Creato da',
		name: 'createdBy',
		type: 'text'
	},
	{
		label: 'Modificato da',
		name: 'modifiedBy',
		type: 'text'
	}
];

export const TYPES_TENANT = [
	{
		label: 'Nome',
		name: 'spotlight',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Descrizione',
		name: 'description',
		type: 'text'
	},
	{
		label: 'Stato',
		name: 'enabled',
		type: 'enum',
		values: [
			{
				label: 'ATTIVO',
				code: true
			},
			{
				label: 'NON ATTIVO',
				code: false
			}
		]
	}
];

export const TYPES_WORKSPACE = [
	{
		label: 'Nome',
		name: 'spotlight',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Descrizione',
		name: 'description',
		type: 'text'
	},
	{
		label: 'Stato',
		name: 'enabled',
		type: 'enum',
		values: [
			{
				label: 'ATTIVO',
				code: true
			},
			{
				label: 'NON ATTIVO',
				code: false
			}
		]
	},
	{
		label: 'Tipologia Workflow',
		name: 'workflowType',
		type: 'text'
	}
];

export const TYPES_MAILBOX = [
	{
		label: 'Nome',
		name: 'spotlight',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Stato',
		name: 'enabled',
		type: 'enum',
		values: [
			{
				label: 'ATTIVO',
				code: true
			},
			{
				label: 'NON ATTIVO',
				code: false
			}
		]
	}
];

export const TYPES_STORYBOARD = [
	{
		label: 'Nome',
		name: 'spotlight',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	},
	{
		label: 'Descrizione',
		name: 'description',
		type: 'text'
	},
	{
		label: 'Stato',
		name: 'status',
		type: 'enum',
		values: [
			{
				label: 'ATTIVO',
				code: 'ACTIVE'
			},
			{
				label: 'NON ATTIVO',
				code: 'INACTIVE'
			},
			{
				label: 'BOZZA',
				code: 'DRAFT'
			}
		]
	}
];

export const TYPES_PRICE_LINES = [
	{
		label: 'Tipo linea',
		name: 'spotlight',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	}
];

export const TYPES_COEFFICENTS = [
	{
		label: 'Coefficiente',
		name: 'spotlight',
		type: 'text',
		default: true,
		defaultOperator: 'like'
	}
];

export const TYPES_TASK_AGENCY_STYLE = [
	{
		label: 'Titolo',
		name: 'subject',
		type: 'text'
	},
	{
		label: 'Assegnato al Reparto',
		name: 'assignWorkspaceName',
		type: 'text'
	},
	{
		label: 'Assegnazione',
		name: 'assign',
		type: 'enum',
		values: [
			{
				label: 'Assegnati a me',
				code: 'me'
			},
			{
				label: 'Tutte le assegnazioni',
				code: 'all'
			}
		]
	},
	{
		label: 'Workflow',
		name: 'storyboard.name',
		type: 'text'
	},
	{
		label: 'Numero Ticket',
		name: 'workflowId',
		type: 'text'
	}
];

export const TYPES_TASK = [
	{
		label: 'Stato lavorazione',
		name: 'workStatus',
		type: 'enum',
		values: [
			{
				label: 'Da lavorare',
				code: 'toWork'
			},
			{
				label: 'Presi in carico',
				code: 'inProgress'
			},
			{
				label: 'Tutti',
				code: 'all'
			},
		]
	},
	{
		label: 'Titolo',
		name: 'subject',
		type: 'text'
	},
	{
		label: 'Assegnato al Reparto',
		name: 'assignWorkspaceName',
		type: 'text'
	},
	{
		label: 'Assegnazione',
		name: 'assign',
		type: 'enum',
		values: [
			{
				label: 'Assegnati a me',
				code: 'me'
			},
			{
				label: 'Tutte le assegnazioni',
				code: 'all'
			}
		]
	},
	{
		label: 'Workflow',
		name: 'storyboard.name',
		type: 'text'
	},
	{
		label: 'Numero Ticket',
		name: 'workflowId',
		type: 'text'
	}
];

export const TYPES_MAILS = [
	{
		label: 'Oggetto',
		name: 'subject',
		type: 'text'
	},
	{
		label: 'Mittente',
		name: 'from',
		type: 'text'
	},
	{
		label: 'Destinatario',
		name: 'to',
		type: 'text'
	}
];

export const RENOVATIONS = [
	{
		id: 'CT',
		value: 'Cappotto termico'
	},
	{
		id: 'GT',
		value: 'Guaina tetto'
	},
	{
		id: 'RF',
		value: 'Rifacimento facciate'
	},
	{
		id: 'RII',
		value: 'Rifacimento impianti idrici'
	},
	{
		id: 'RIE',
		value: 'Rifacimento impianti elettrici'
	},
	{
		id: 'RT',
		value: 'Rifacimento tetto'
	},
	{
		id: 'SI',
		value: 'Sostituzione infissi'
	},
	{
		id: 'SPI',
		value: 'Sostituzione pavimenti interni'
	}
];
